<template>

    <v-container>
        <spinner v-if="loadingPage" />
        <v-row v-else class="py-10">
            <v-col class="d-flex justify-center text-center" md="12">
                <h2>{{ $t("Edit Quiz") }}</h2>
            </v-col>
            <v-col md="3">
                <v-text-field outlined dense :dark="$store.state.isDarkMode" :label="$t('Quiz Name')"
                    v-model="structure.name"></v-text-field>
            </v-col>
            <v-col md="3">
                <v-text-field outlined dense :dark="$store.state.isDarkMode" type="date" :label="$t('Activate Date')"
                    v-model="structure.activate_date"></v-text-field>
            </v-col>
            <v-col md="3">
                <v-text-field outlined dense :dark="$store.state.isDarkMode" type="number" :label="$t('Time')"
                    v-model="structure.time"></v-text-field>
            </v-col>
            <v-col md="3">
                <v-text-field outlined dense :dark="$store.state.isDarkMode" type="number" :label="$t('Attempt Numbers')"
                    v-model="structure.repeat_number"></v-text-field>
            </v-col>
            <v-col md="3">
                <AutoCompleteField outlined :label="$t('class')" v-model="structure.grade_id" endPoint="/grade" returnObject/>
            </v-col>
            <v-col md="3">
                <AutoCompleteField outlined :label="$t('educitional class')" v-model="structure.edu_class_id" returnObject
                    endPoint="/edu-class" />
            </v-col>
            <v-col md="12">
                <v-card flat outlined>
                    <v-row>
                        <v-col class="py-5" md="12">
                            <h3>{{ $t('Choose Questions') }}</h3>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0 mx-4" md="3">
                            <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.subject_id" :items="subjects"
                                :label="$t('subject')" item-text="name" item-value="id"
                                color="var(--bg-color--font)"></v-autocomplete>
                        </v-col>
                        <v-col class="pb-0 mx-4" md="3">
                            <v-autocomplete :dark="$store.state.isDarkMode" v-model="filters.unit_id" :items="units"
                                :label="$t('Unit')" item-text="name" item-value="id"
                                color="var(--bg-color--font)"></v-autocomplete>
                        </v-col>
                        <v-col class="pb-0 mx-4" md="3">
                            <v-text-field :dark="$store.state.isDarkMode" :label="$t('Question Text')"
                                v-model="filters.name"></v-text-field>
                        </v-col>
                        <v-col class="py-9 mx-4" md="1">
                            <v-icon color="var(--bg-font--color)" class="mr-2" @click="fetchData()">mdi-magnify</v-icon>
                        </v-col>
                    </v-row>
                    <v-row justify="start" class="ma-0 mt-2">
                        <v-col>
                            <div style="overflow: auto; max-height: 400px;">
                                <v-data-table :dark="$store.state.isDarkMode" :key="tableKey1" :headers="headers"
                                    :items="questions" item-key="id" @click:row="filternames" hide-default-footer disable-pagination
                                     style="border: 1px solid #ccc; border-radius: 4px; cursor: pointer;" :loading="loading">
                                    <template v-slot:header.actions="{ headers }">
                                        <v-icon @click="convertSelectAll">mdi-swap-horizontal</v-icon>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                        <v-col>
                            <div style="overflow: auto; max-height: 400px;">
                                <v-data-table :dark="$store.state.isDarkMode" :key="tableKey2" :headers="selectedHeaders"
                                    :items="structure.questions" item-key="id" @click:row="RemoveSelectedItem"
                                    hide-default-footer disable-pagination
                                    style="border: 1px solid #ccc; border-radius: 4px;  cursor: pointer;"
                                    :loading="loading">
                                    <template v-slot:header.actions="{ selectedHeaders }">
                                        <v-icon @click="convertNotSelectAll">mdi-swap-horizontal</v-icon>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-col>
                    </v-row>
                    <v-container class="py-10" v-if="structure.questions.length" id="buffet-table" fluid tag="section">
                <v-simple-table :dark="$store.state.isDarkMode" style=" width: max-content;">
                  <tr>
                    <th id="idAutoCompA">{{ $t('Question Text') }}</th>
                    <th id="idPrice">{{ $t('Mark') }}</th>
                    <th id="idPrice">{{ $t('Time') }}</th>
                    <th></th>
                  </tr>
                  <v-fade-transition mode="out-in" :group="true" tag="tbody">
                    <tr v-for="(el, ix) in structure.questions" :key="ix">
                      <td>
                        <v-text-field full-width dense hide-details readonly flat color="#757575" v-model="el.name"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field full-width dense hide-details type="number" flat color="#757575" v-model="el.mark"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-text-field  full-width type="number" dense hide-details flat
                          color="#757575" v-model="el.time"></v-text-field>
                      </td>
                      <td>
                        <v-icon @click="deleteRowI(ix)" color="red"
                          style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
                      </td>
                    </tr>
                  </v-fade-transition>
                </v-simple-table>
              </v-container>
                </v-card>
            </v-col>
            <v-col md="4">
                <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ $t("edit")
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="$router.go(-1)">
                    {{ $t('cancel') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import spinner from "../../../../../components/base/spinner.vue";

import i18n from '@/i18n';
export default {
    components: { AutoCompleteField, spinner },
    data() {
        return {
            loadingPage: false,
            tableKey1: 0,
            tableKey2: 0,
            headers: [{ text: i18n.t("All Questions"), value: "name" }, { text: 'Actions', value: 'actions', sortable: false }],
            selectedHeaders: [{ text: i18n.t("Selected Questions"), value: "name" }, { text: 'Actions', value: 'actions', sortable: false }],
            questions: [],
            dataQuestions: [],
            btnLoading: false,
            dialog: false,
            loading: false,
            structure: {
                name: null,
                time: null,
                activate_date: null,
                questions: [],
                repeat_number: null,
                grade_id: null,
                edu_class_id: null,
            },
            filters: {
                name: null,
                unit_id: null,
                subject_id: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            units: "subjects/getUnits",
            subjects: "subjects/getSubjects",
        }),
    },
    methods: {
        ...mapActions({
            fetchUnits: "subjects/fetchUnits",
            fetchSubjects: "subjects/fetchSubjects",
        }),
        filternames(item) {
            for (let i = 0; i < this.questions.length; i++) {
                if (this.questions[i].name == item.name) {
                    this.questions.splice(i, 1);
                }
            }
            this.structure.questions.push(item);
        },
        RemoveSelectedItem(item) {
            for (let i = 0; i < this.structure.questions.length; i++) {
                if (this.structure.questions[i] == item) {
                    this.structure.questions.splice(i, 1);
                }
            }
        },
        convertSelectAll() {
            for (let i = 0; i < this.questions.length; i++) {
                this.structure.questions.push(this.questions[i]);
            }
            this.questions = [];
        },
        convertNotSelectAll() {
            this.structure.questions = [];
        },
        deleteRowI(index) {
      if (this.structure.questions.length == 1) return;
      this.structure.questions.splice(index, 1);
    },
        async fetchData() {
            if(!this.filters.name && !this.filters.subject_id && !this.filters.unit_id){
                this.$Notifications(
                    this.$t('One of the filters is required'),
                    { rtl: true, timeout: 1000 },
                    "error"
                );
                return
            }
            this.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                paginated: 'false',
                ...filterTemp,
            };
            const response = await axios.get(`/lms/question`, { params });
            if (response.status == 200) {
                this.questions = response.data.data;  
            }
            this.loading = false;
        },
        clearFilter() {
            this.filters.name = undefined;
            this.filters.subject_id = undefined;
            this.filters.unit_id = undefined;
            this.fetchData();
        },
        async submit() {
            try {
                this.btnLoading = true;
                this.structure.questions = this.structure.questions.map((el)=> ({
                    question_id: el.id,
                    mark: el.mark,
                    time: el.time,
                }));
                this.structure.grade_id = this.structure.grade_id?.id;
                this.structure.edu_class_id = this.structure.edu_class_id?.id;
                await axios.put(`/lms/quiz/${this.$route.params.id}`, this.structure);
                this.$router.go(-1);
                this.$Notifications(
                    this.$t('add success'),
                    { rtl: true, timeout: 2000 },
                    "success"
                );
            } catch (err) {
            } finally {
                this.btnLoading = false;
            }
        },
    },
    async created() {
        try {
            this.loadingPage = true;
            const res = await axios.get(`/lms/quiz/${this.$route.params.id}`);
            this.structure = res.data.data;
            this.structure.grade_id = res.data.data.grade;
            this.structure.edu_class_id = res.data.data.edu_class;
        } catch (error) {
        }
        finally{
            this.loadingPage = false;
        }
        this.fetchSubjects();
        this.fetchUnits();
    },
};
</script>


<style scoped lang="scss">
table {
  border-collapse: collapse;
  table-layout: fixed;
}

.tableId {
  border-radius: 10%;
}

td,
th {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
}

table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  border-radius: 10%;
}

#idAutoComp {
  padding-left: 70px;
  padding-right: 70px;
}

#idAutoCompA {
    padding-left: 230px;
    padding-right: 230px;
}


#idPrice {
  padding-left: 40px;
  padding-right: 40px;
}

#buffet-table {
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
}

#buffet-table th {
  color: #909399;
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
  text-align: center;
  width: max-content;
}

#buffet-table tr {
  transition: 0.5s;
}

#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
  width: max-content;
}

.add-user__chip1 {
  border: 1px solid #757575;
  border-radius: 10px;
  margin: 5px 0;
  width: 100%;
}

.columnSelector:hover {
  border-right: 2px solid blue;
}

.cardd {
  border: 1px solid #a2a2a2;
  width: 100%;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 4px 4px;
}

.cardd .preview-img {
  width: 100%;
}</style>